html,
body {
  height: 100%;
  margin: 0;
  font-family: proxima_nova_regular, Helvetica, Arial, sans-serif;
  font-size: 0.9em;
}

.map {
  height: 100%;
  width: 100%;
}

#controls-left {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 30em;
  max-width: 30em;
  height: 100%;
  background-color: rgba(255, 255, 255);
  margin-bottom: 100px;
  overflow-x: auto;
  overflow-y: auto;
}

.directional-control {
  position: static;
  height: 170px;
  background-color: transparent;
  border-top: grey 1px solid;
  border-radius: 0px;
  padding: 5px;
}

.directional-button {
  position: absolute;
}

.wkt-input {
  border-top: grey 1px solid;
  position: static;
  width: 90%;
  padding: 5px;
}

.wkt-input textarea {
  width: 100%;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  font-family: monospace;
  font-size: 1.1em;
}

.wkt-input textarea.error {
  background-color: rgb(240, 172, 172);
}

.lat-long-input {
  position: static;
  z-index: 10;
  padding-left: 5px;
  background-color: transparent;
}

.date-input {
  position: static;
  z-index: 10;
  padding: 0px;
}

.date-input li {
  display: block;
  padding: 5px 10px;
}

.sdo-input {
  position: static;
  z-index: 10;
  padding: 5px;
  background-color: transparent;
  border-bottom: grey 1px solid;
}

.sdo-checkbox {
}

.sdo-text {
  margin-left: 5px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sdo-area {
  margin-top: 2px;
  margin-bottom: 0px;
}

.sdo-sign-span-div {
  margin-bottom: 8px;
  color: white;
  margin: 1px;
  padding: 4px;
  padding-left: 5px;
  white-space: nowrap;
  border: solid 1px rgb(113, 140, 174);
  border-radius: 5px;
  background-color: rgb(113, 140, 174);
  width: min-content;
}

.sdo-sign-button {
  background-color: #f55a67;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: bold;
  padding: 4px 6px;
  text-decoration: none;
  text-align: center;
}

.sdo-sign-button:hover {
  background-color: #bd2a53;
}

.login {
  width: 100%;
  text-align: center;
  top: 0px;
  position: absolute;
}

.login-btn {
  margin: 0 auto;
  background-color: #6495ed;
  border: none;
  color: white;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

.login-btn:hover {
  background-color: #4f83e4;
  cursor: pointer;
}

.data-source {
  position: block;
  z-index: 10;
  padding: 5px;
  background-color: transparent;
  border-bottom: grey 1px solid;
}

.data-source-label {
  padding: 0px;
  margin: 0px;
}

.vector-feature {
  position: block;
  z-index: 10;
  padding: 5px;
  background-color: transparent;
}

.vector-feature-checkbox {
}

.vector-feature-text {
  margin-left: 5px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.layer-active {
  color: black;
}

.layer-inactive {
  color: grey;
  max-width: 40em;
}

table.vector-feature-attributes-list {
  width: 90%;
}

table.vector-feature-attributes-list,
table.feature-info-list {
  max-width: 90%;
  table-layout: fixed;
}

.vector-feature-attributes-list,
.feature-info-list {
  font-size: 1em;
  padding-left: 0.3em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

.vector-feature-subattributes-list {
  font-size: 1em;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.vector-feature-attributes-list col.label,
.vector-feature-attributes-list td.label {
  width: 30%;
}

.vector-feature-attributes-list col.value,
.vector-feature-attributes-list td.value {
  width: 70%;
}

.vector-feature-attributes-list td {
  vertical-align: top;
  overflow-wrap: break-word;
}

.vector-feature-subattributes-list td {
  vertical-align: top;
  overflow-wrap: break-word;
}

/* Geocoder Control: Start */
#gcd-container {
  position: absolute;
  top: 0px;
  left: 30.5em;
  z-index: 1000;
  pointer-events: none;
}

.gcd-gl-control {
  width: 1.7em;
  height: 2em;
  overflow: hidden;
  /*transition: width 200ms, height 200ms;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.gcd-gl-control button {
  margin-left: 3px;
}

.gcd-gl-control button::before {
  content: "";
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 0.7em;
  height: 0.7em;
  background-color: transparent;
  border: 0.12em solid currentColor;
  border-radius: 100%;
  top: 0.35em;
  left: 0.35em;
}

.gcd-gl-control button::after {
  content: "";
  position: absolute;
  top: 1.1em;
  left: 0.95em;
  width: 0.45em;
  height: 0.15em;
  background-color: currentColor;
  border-radius: 0.05em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-box-shadow: -0.18em 0 0 -0.03em;
  box-shadow: -0.18em 0 0 -0.03em;
}

.gcd-gl-control input {
  display: block;
}

.gcd-gl-expanded {
  width: 14em;
}

.gcd-gl-expanded input {
  width: 11em;
  height: 1.2em;
  display: inline;
}

.ol-geocoder ul.gcd-gl-result {
  position: absolute;
  top: 2.1875em;
  left: 2em;
  width: 16.25em;
  max-height: 18.75em;
  white-space: normal;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: white;
  border-radius: 4px;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.8);
  transition: max-height 300ms ease-in;
}

ul.gcd-gl-result > li:nth-child(odd) {
  background-color: #e0ffe0;
}

ul.gcd-gl-result > li {
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #eee;
  padding: 0;
  line-height: 0.875rem;
}

ul.gcd-gl-result > li > a {
  display: block;
  text-decoration: none;
  padding: 3px 5px;
}

.gcd-road {
  font-size: 0.875em;
  font-weight: 500;
  color: #333;
}

/* Geocoder Control: End */
/* Layer Switcher Control: Start */
.layer-switcher {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  text-align: left;
}

.layer-switcher .panel {
  margin: 0;
  border: 4px solid #eee;
  border-radius: 4px;
  background-color: white;
  display: none;
  max-height: inherit;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.layer-switcher button {
  float: right;
  z-index: 1;
  width: 36px;
  height: 36px;
  margin: -2px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACE1BMVEX///8A//8AgICA//8AVVVAQID///8rVVVJtttgv98nTmJ2xNgkW1ttyNsmWWZmzNZYxM4gWGgeU2JmzNNr0N1Rwc0eU2VXxdEhV2JqytQeVmMhVmNoydUfVGUgVGQfVGQfVmVqy9hqy9dWw9AfVWRpydVry9YhVmMgVGNUw9BrytchVWRexdGw294gVWQgVmUhVWPd4N6HoaZsy9cfVmQgVGRrytZsy9cgVWQgVWMgVWRsy9YfVWNsy9YgVWVty9YgVWVry9UgVWRsy9Zsy9UfVWRsy9YgVWVty9YgVWRty9Vsy9aM09sgVWRTws/AzM0gVWRtzNYgVWRuy9Zsy9cgVWRGcHxty9bb5ORbxdEgVWRty9bn6OZTws9mydRfxtLX3Nva5eRix9NFcXxOd4JPeINQeIMiVmVUws9Vws9Vw9BXw9BYxNBaxNBbxNBcxdJexdElWWgmWmhjyNRlx9IqXGtoipNpytVqytVryNNrytZsjZUuX210k5t1y9R2zNR3y9V4lp57zth9zdaAnKOGoaeK0NiNpquV09mesrag1tuitbmj1tuj19uktrqr2d2svcCu2d2xwMO63N+7x8nA3uDC3uDFz9DK4eHL4eLN4eIyYnDX5OM5Z3Tb397e4uDf4uHf5uXi5ePi5+Xj5+Xk5+Xm5+Xm6OY6aHXQ19fT4+NfhI1Ww89gx9Nhx9Nsy9ZWw9Dpj2abAAAAWnRSTlMAAQICAwQEBgcIDQ0ODhQZGiAiIyYpKywvNTs+QklPUlNUWWJjaGt0dnd+hIWFh4mNjZCSm6CpsbW2t7nDzNDT1dje5efr7PHy9PT29/j4+Pn5+vr8/f39/f6DPtKwAAABTklEQVR4Xr3QVWPbMBSAUTVFZmZmhhSXMjNvkhwqMzMzMzPDeD+xASvObKePPa+ffHVl8PlsnE0+qPpBuQjVJjno6pZpSKXYl7/bZyFaQxhf98hHDKEppwdWIW1frFnrxSOWHFfWesSEWC6R/P4zOFrix3TzDFLlXRTR8c0fEEJ1/itpo7SVO9Jdr1DVxZ0USyjZsEY5vZfiiAC0UoTGOrm9PZLuRl8X+Dq1HQtoFbJZbv61i+Poblh/97TC7n0neCcK0ETNUrz1/xPHf+DNAW9Ac6t8O8WH3Vp98f5lCaYKAOFZMLyHL4Y0fe319idMNgMMp+zWVSybUed/+/h7I4wRAG1W6XDy4XmjR9HnzvDRZXUAYDFOhC1S/Hh+fIXxen+eO+AKqbs+wAo30zDTDvDxKoJN88sjUzDFAvBzEUGFsnADoIvAJzoh2BZ8sner+Ke/vwECuQAAAABJRU5ErkJggg==")
    /*logo.png*/;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 2px;
  color: black;
  border: none;
}

.layer-switcher button:focus,
.layer-switcher button:hover {
  background-color: white;
}

.layer-switcher.shown {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 5.5em);
}

.layer-switcher.shown.ol-control {
  background-color: transparent;
}

.layer-switcher.shown.ol-control:hover {
  background-color: transparent;
}

.layer-switcher.shown .panel {
  display: block;
}

.layer-switcher.shown button {
  display: none;
}

.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  display: block;
  background-image: unset;
  right: 2px;
  position: absolute;
  background-color: #eee;
  margin: 1px;
}

.layer-switcher.shown button:focus,
.layer-switcher.shown button:hover {
  background-color: #fafafa;
}

.layer-switcher ul {
  list-style: none;
  margin: 1.6em 0.4em;
  padding-left: 0;
}

.layer-switcher ul ul {
  padding-left: 1.2em;
  margin: 0.1em 0 0 0;
}

.layer-switcher li.group + li.group {
  margin-top: 0.4em;
}

.layer-switcher li.group + li.layer-switcher-base-group {
}

.layer-switcher li.group > label {
  font-weight: bold;
}

.layer-switcher.layer-switcher-group-select-style-none li.group > label {
  padding-left: 1.2em;
}

.layer-switcher li {
  position: relative;
  margin-top: 0.3em;
}

.layer-switcher li input {
  position: absolute;
  left: 1.2em;
  height: 1em;
  width: 1em;
  font-size: 1em;
}

.layer-switcher li label {
  padding-left: 2.7em;
  padding-right: 1.2em;
  display: inline-block;
  margin-top: 1px;
}

.layer-switcher label.disabled {
  opacity: 0.4;
}

.layer-switcher input {
  margin: 0px;
}

.layer-switcher.touch ::-webkit-scrollbar {
  width: 4px;
}

.layer-switcher.touch ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.layer-switcher.touch ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

li.layer-switcher-base-group > label {
  padding-left: 1.2em;
}

.layer-switcher .group button {
  position: absolute;
  left: 0;
  display: inline-block;
  vertical-align: top;
  float: none;
  font-size: 1em;
  width: 1em;
  height: 1em;
  margin: 0;
  background-position: center 2px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAW0lEQVR4nGNgGAWMyBwXFxcGBgaGeii3EU0tXHzPnj1wQRYsihqQ+I0ExDEMQAYNONgoAN0AmMkNaDSyQSheY8JiaCMOGzE04zIAmyFYNTMw4A+DRhzsUUBtAADw4BCeIZkGdwAAAABJRU5ErkJggg==");
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}

.layer-switcher .group.layer-switcher-close button {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}

.layer-switcher .group.layer-switcher-fold.layer-switcher-close > ul {
  overflow: hidden;
  height: 0;
}

/*layerswitcher on the right*/
.layer-switcher.shown.layer-switcher-activation-mode-click {
  padding-left: 34px;
}

.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  left: 0;
  border-right: 0;
}

/* Layer Switcher Control: End */
#feature-info {
  position: absolute;
  bottom: 0;
  left: 30.5em;
  /*display: inline-block;*/
  height: auto;
  width: auto;
  z-index: 100;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 30px;
  transform: translateX(3%);
  visibility: visible;
  pointer-events: none;
}

details {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  transition: background-color 0.3s;
}

summary {
  cursor: pointer;
  font-weight: bold;
  outline: none;
}
